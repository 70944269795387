import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownShortWide,
  faBook,
  faBrain,
  faChartLine,
  faCheckCircle,
  faFish,
  faLaptop,
  faMagnifyingGlassChart,
  faServer,
  faStopwatch,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";

const StatisticsHero = () => {
  return (
    <div>
      <div className="flex-col py-4 px-4 my-5 lg:my-15 mx-auto max-w-screen-xl lg:px-6 flex justify-center bg-gray-50 dark:bg-gray-800 border-r-emerald-200 rounded-xl">
        <h2 className="text-center my-4 text-4xl tracking-tight font-extrabold">In Figures.</h2>
        <div className="space-y-5 mx-auto my-5 lg:my-10 md:grid md:grid-cols-3  md:space-y-0 gap-10 max-w-screen-md">
          <div className="md:text-start text-center">
            <h3 className="text-xl md:text-2xl font-bold text-base-content tracking-tighter text-center">
              <FontAwesomeIcon icon={faBook} className="mr-2  text-primary" /> +10,000{" "}
            </h3>
            <p className="text-sm text-base-content/80 text-center ">Pages has been digest by our algorithms</p>
          </div>
          <div className="md:text-start text-center ">
            <h3 className=" text-center text-xl md:text-2xl font-bold text-base-content tracking-tighter">
              {" "}
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-primary" /> 84%
            </h3>
            <p className="text-center text-sm text-base-content/80">of Guru Stocks Screener consistently beat the SP500 since 1980</p>
          </div>
          <div className="md:text-start text-center">
            <h3 className=" text-xl md:text-2xl font-bold text-base-content tracking-tighter text-center">
              <FontAwesomeIcon icon={faArrowDownShortWide} className="mr-2 text-primary" /> +14,000
            </h3>
            <p className=" text-sm text-base-content/80 text-center">
              stocks are under analysis every week, with hundreds of figures for each of them.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsHero;
