import React from "react";
import { Helmet } from "react-helmet";
import Testimonials from "./components/Testimonials";
import Faq from "../../components/FaqLanding";
import FeaturesCTA from "./components/FeaturesCTA";
import CTASection from "../../components/CTASection";
import Specifications from "./components/Specifications";
import BackgroundHomePage from "./components/BackgroundHomePage";
import HeroWelcome from "./components/HeroWelcome";
import HowWeWork from "./components/HowWeWork";
import StatisticsHero from "./components/StatisticsHero";
import Painvsgain from "./components/Painvsgain";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>{`GuruScreener | Your Solution to Effortless Stock Screening`}</title>‍
      </Helmet>
      <BackgroundHomePage />
      <HeroWelcome />
      <Painvsgain />
      <StatisticsHero />
      <FeaturesCTA />
      {/* <CardHero /> */}
      <Specifications />
      <Testimonials />
      <HowWeWork />

      <Faq />
      <CTASection />
    </>
  );
};

export default HomePage;
