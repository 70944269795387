import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faBook } from "@fortawesome/free-solid-svg-icons";
import fiftyoneTipsCover from "../../assets/image/ebook/51-tips-for-investing-in-stocks.png";
import { UserContext } from "../../context/usercontext";
import { Link } from "react-router-dom";

const ButtonEbook = ({ data }) => {
  const { user, hasEbook, hasSubscription } = data;

  const link =
    "https://firebasestorage.googleapis.com/v0/b/guruscreener-io.appspot.com/o/51%20Advices%20To%20Invest%20in%20Stocks%20-%20Tim%20J.%20Brown.pdf?alt=media&token=6c083c18-9b39-4b9b-b2fc-86a5c242e15c";

  if (user) {
    if (hasEbook || hasSubscription) {
      return (
        <a href={link}>
          <button className="btn btn-primary tooltip w-64">
            <FontAwesomeIcon icon={faArrowDown} className="mr-3 " />
            Download the eBook
          </button>
        </a>
      );
    } else {
      return (
        <a href={`https://buy.stripe.com/5kAcPh2kuaIF8Uw144?client_reference_id=${user.uid}`} target="_blank" rel="noreferrer noopener">
          <button className="btn btn-primary tooltip w-64">
            <FontAwesomeIcon icon={faArrowDown} className="mr-3 " />
            Buy the eBook
          </button>
        </a>
      );
    }
  }

  if (!user) {
    return (
      <Link to={`${process.env.PUBLIC_URL}/signup`}>
        <button className="btn btn-primary tooltip w-64">
          <FontAwesomeIcon icon={faArrowDown} className="mr-3 " />
          Buy the eBook
        </button>
      </Link>
    );
  }
};

const EBook = () => {
  const { user, hasSubscription, hasEbook } = useContext(UserContext);

  return (
    <div className="max-w-3xl mx-auto mt-20 sm:mt-40">
      <h1 className="text-3xl font-bold tracking-tight sm:text-3xl text-center">
        {" "}
        <FontAwesomeIcon icon={faBook} className="mr-2" /> GuruScreener Academy
      </h1>
      <div className="mt-6 text-lg leading-8 text-base-content">
        <p>Improve your knowledge of stock investing with this collection of eBooks.</p>
      </div>
      <div className="grid sm:grid-cols-4 mt-5">
        <div className="flex justify-center sm:items-center col-span-2 ">
          <img className="rounded-lg shadow-xl " src={fiftyoneTipsCover} alt="Cover of the book" />
        </div>
        <div className="col-span-2">
          <h2 className="text-center text-xl font-bold tracking-tight sm:text-lg pb-0 mt-5 "> 51 Tips for Investing in Stocks</h2>
          <p className="text-center  text-lg leading-8 text-base-content/80">Tim J. Brown</p>
          <p className="  mt-14">
            Comprehensive advice for successful stock market investing. 51 Expert-Backed tips for safeguarding your investments, navigating market
            fluctuations, and achieving financial prosperity.
          </p>
          <br />
          <p className="">
            This guide compiles 51 great tips that blend popular wisdom with quotations from the great names in finance (Warren Buffett, Philip
            Fisher,...) and philosophy (Socrates, Pascal,...). Stock market tips are set out in the form of short maxims.
          </p>
          <br></br>
          <div>
            You'll find advice on a wide range of topics, including:
            <ul className="list-disc list-inside ml-5">
              <li>What you need to know before investing</li>
              <li>Tips and criteria for selecting assets </li>
              <li>Identify the best times to buy and sell</li>
            </ul>
          </div>
          <br></br>
          <p className="">
            {" "}
            <b>Price: 15$</b>
          </p>
          <br></br>

          <ButtonEbook data={{ user, hasEbook, hasSubscription }} />
        </div>
      </div>
    </div>
  );
};

export default EBook;
