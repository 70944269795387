import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNodes, faChartSimple, faList, faFlagCheckered, faHourglassHalf, faMicroscope, faMugHot } from "@fortawesome/free-solid-svg-icons";
import feature5 from "../../../assets/image/Features-high-return.png";
import feature2 from "../../../assets/image/Features-data.png";
import feature11 from "../../../assets/image/Features-save-time.svg";
import feature1Dark from "../../../assets/image/Minimalist_Img1_dark.svg";
import feature2Dark from "../../../assets/image/Minimalist_Img2_dark.svg";
import feature3Dark from "../../../assets/image/Minimalist_Img3_dark.svg";
// import { faHourglass } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

const FeaturesCTA = () => {
  return (
    <>
      <div className="gap-8 items-center py-20 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-20 lg:px-6 duration-1000 delay-300">
        <span className="dark:hidden md:hidden flex justify-center">
          <img className="" src={feature5} alt="Image Feature 1" />
        </span>
        <span className="hidden md:hidden md:dark:hidden dark:flex justify-center">
          <img className="" src={feature1Dark} alt="Image Feature 1" />
        </span>

        {/* <div className="relative isolate flex items-center gap-x-6 bg-gray-50 px-6 py-2.5 sm:px-3.5 ">
          <div className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
        </div> */}

        <div className="mt-8 md:mt-0">
          {/* <div className="w-16 h-16 rounded-full ring-1  dark:border-2 dark:border-sky-500 ring-slate-900/10 shadow overflow-hidden dark:bg-sky-600 dark:highlight-white/20 text center">
						<FontAwesomeIcon className="h-8 mt-[15px] ms-[18px] text-sky-600 dark:text-slate-100" icon={faFlagCheckered} />
					</div> */}
          <p className="mt-8 text-lg font-semibold text-sky-500">Exceed Your Financial Goals</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">High Return Strategies</h2>
          <div className="py-0 mb-6 text-base-content/90 text-lg">
            Imagine this: you not only beat the S&P 500, you crush it.
            <br />
            <br />
            Sounds like a dream? Well, it's about to get real. Here at Guruscreener.io, we're all about turning those financial fantasies into your
            everyday reality.
            {/* <br />
            <br />
            Discover a <b>selection of stocks</b> that have been selected using strategies derived from famous investment books.
            <br />
            <br />
            <b>You're not just investing, you're preparing to outperform.</b> */}
            <ul>
              <li>
                {" "}
                <span className="flex items-center mt-4 mb-2">
                  <span className="flex rounded-full w-8 h-8 bg-sky-100 dark:bg-sky-300 grow-0 shrink-0 items-center justify-center mr-4">
                    <FontAwesomeIcon icon={faList} className=" text-sky-700 text-sm " />
                  </span>{" "}
                  <b className="text-bold">Stock Selection</b>
                </span>
                Stocks that have been selected using strategies derived from famous investment books.
              </li>
              <li>
                <span className="flex items-center mt-5 mb-2">
                  <span className="flex rounded-full w-8 h-8 bg-sky-100 dark:bg-sky-300 grow-0 shrink-0 items-center justify-center mr-4">
                    <FontAwesomeIcon icon={faChartSimple} className=" text-sky-700 text-sm " />
                  </span>{" "}
                  <b className="text-bold">Beat SP500 every year</b>
                  <span></span>
                </span>
                You're not just investing, you're preparing to outperform.
              </li>
            </ul>
          </div>
          <Link
            to={`/screener`}
            className="inline-flex items-center rounded-lg btn btn-accent border-0 bg-sky-100 text-sky-600 hover:bg-sky-200
            hover:text-sky-700 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 dark:hover:text-white "
          >
            {" "}
            Discover the Performing Stocks
            <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
        <span className="hidden md:flex dark:hidden justify-center">
          <img className="" src={feature5} alt="Image Feature 1" />
        </span>
        <span className="hidden md:dark:flex justify-center">
          <img className="w-[500px]" src={feature5} alt="Image Feature 1" />
        </span>
      </div>

      <div className="gap-8 items-center py-20 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-20 lg:px-6 duration-1000 delay-300">
        <span className="flex dark:hidden justify-center">
          <img className="" src={feature2} alt="Image Feature 2" />
        </span>
        <span className="dark:flex hidden justify-center">
          <img className="" src={feature2} alt="Image Feature 2" />
        </span>
        <div className="mt-8 md:mt-0">
          {/* <div className="w-16 h-16 rounded-full ring-2 dark:border-2 dark:border-pink-500 ring-slate-900/10 shadow overflow-hidden dark:bg-pink-700 dark:highlight-white/30">
						<FontAwesomeIcon className="h-8 mt-[15px] ms-[13px] text-pink-500 dark:text-slate-100" icon={faMicroscope} />
					</div> */}

          <p className="mt-8 text-lg font-semibold text-pink-500 dark:text-pink-400">Proven to work</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">Reliable Data And Algorithm</h2>
          <p className="py-0 mb-6 text-base-content/90 text-lg">
            Why trust us?
            <br />
            <br />
            Confidence in your <b>investment decisions</b> comes from knowing that the strategy you are implementing has been tested.
            <br />
            <br />
            We don't just promise access to <b>elite investment strategies</b>, we guarantee peace of mind with data-driven proof.
            {/* <br />
            <br />
            Our stock picks are not only inspired by the greatest financial minds, but are backed by rigorous backtesting to ensure these strategies
            have consistently outperformed the market. */}
            <br />
            <br />
            And guess what? <b>We are the first users</b> of this strategy, we now invest exclusively on the basis of this stock selection and our
            results have exceeded expectations.
          </p>

          {/* <p className="mt-8 text-lg font-semibold text-pink-500 dark:text-pink-400">Multiple strategies</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">Access a Wealth of Strategies in One Place</h2>
          <p className="py-0 mb-6 text-base-content/90">
            Why limit yourself to just one strategy?
            <br />
            We brings you a diverse range of stock screeners, each meticulously curated from the pages of famous books and literature.
            <br />
            <br />
            Access dozens of strategies on a single platform, giving you the flexibility to choose the one that best aligns with your investment
            goals.
            <br />
            <br />
          </p> */}

          <Link
            to={`/screener`}
            className="inline-flex items-center rounded-lg  btn  border-0 bg-pink-100 text-pink-600 hover:bg-pink-200 hover:text-pink-700  dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 dark:hover:text-white "
          >
            Invest like the Guru's
            <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>

      <div className="gap-8 items-center py-20 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-20 lg:px-6 duration-1000 delay-300">
        <span className="dark:hidden md:hidden flex justify-center">
          <img className="" src={feature11} alt="Image Feature 3" />
        </span>
        <span className="hidden md:hidden md:dark:hidden dark:flex justify-center">
          <img className="" src={feature11} alt="Image Feature 3" />
        </span>
        <div className="mt-8 md:mt-0">
          {/* <div className="w-16 h-16 p-[0.1875rem] rounded-full ring-1 dark:border-2 dark:border-indigo-500 ring-slate-900/10 shadow overflow-hidden dark:bg-indigo-600 dark:highlight-white/10">
						<FontAwesomeIcon className="h-8 mt-3 ms-4 text-indigo-500 dark:text-slate-100" icon={faHourglassHalf} />
					</div> */}

          <p className="mt-8 text-lg	font-semibold text-indigo-500 dark:text-indigo-400">Effortless Investing</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">Stop wasting time and get results faster</h2>
          <p className="py-0 mb-6 text-base-content/90 text-lg">
            You can become a financial wizard by reading the financial books and implementing the algorithm in them. But wait,{" "}
            <b>do you have the time</b> to read all those books?
            <br /> <br />
            On top of that, you have to build (really) complex Excel spreadsheets and pay high fees for up-to-date financial data.
            <br />
            <br />
            That's why we created GuruScreener, to condense all this complex and time-consuming process into just a few clicks, so you can focus on
            what really matters : <b>growing your wealth.</b>
            <br />
            <br />
          </p>

          {/* 

           <p className="mt-8 text-lg	font-semibold text-indigo-500 dark:text-indigo-400">Backtested Strategies</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">Confidence in Every Decision</h2>
          
          <p className="mt-8 text-lg	font-semibold text-indigo-500 dark:text-indigo-400">Backtested Strategies</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">Confidence in Every Decision</h2>
          <p className="py-0 mb-6 text-base-content/90">
            Don't just take our word for it – each stock screener on Guruscreener.io undergoes rigorous backtesting.
            <br />
            This ensures that you make informed decisions based on real results.
            <br />
            <br />
            Invest with confidence, knowing that our platform empowers you with accurate and reliable data.
            <br />
            <br />
          </p> */}

          <Link
            to={`/screener`}
            className="inline-flex items-center rounded-lg  btn btn-accent border-0 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-700  dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 dark:hover:text-white "
          >
            Start Investing Effortlessly
            <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
        <span className="hidden md:flex dark:hidden justify-center">
          <img className="w-[500px]" src={feature11} alt="Image Feature 3" />
        </span>
        <span className="hidden md:dark:flex justify-center">
          <img className="w-[500px]" src={feature11} alt="Image Feature 3" />
        </span>
      </div>
    </>
  );
};

export default FeaturesCTA;
